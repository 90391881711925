import React from "react";
import { FormattedMessage } from "react-intl";
import { Link, NavLink, useLocation } from "react-router-dom";
import { Icon } from "@netmedi/frontend-design-system";
import Logo from "common/components/Logo";
import {
  clientMainNavHandle,
  clientMainNavItemHandle,
} from "common/utils/semanticHandles";
import { NavItem } from "./navItem";
import {
  SidebarContainer,
  StyledSidebar,
  StyledSidebarItem,
  StyledSidebarLogo,
  StyledSidebarList,
  StyledSidebarProfile,
  StyledName,
  StyledSidebarListItem,
  StyledSidebarLinkText,
  ProfileButton,
} from "./Sidebar.styles";
import { InitialismIconStatic } from "../Profile/InitialsIcon";
import { UserProps, generateName } from "../Profile/helpers";
import { User } from "common/models/user";
import {
  profileSelectorShowable,
  resetProfileSelectorCallback,
} from "../ProfileSelector/helpers";
import { ProfileSelectorSidebarWrapper } from "../ProfileSelector/ProfileSelector.styles";

const renderItem = (item: NavItem, i: number) => {
  const { badge, text, icon, section, to, href, spacingAfter, ...rest } = item;
  const linkProps = { ...rest, activeClassName: "Sidebar_active" };
  const linkChildren = (
    <>
      {icon && <Icon name={icon} size="medium" badge={badge} />}
      <StyledSidebarLinkText>
        <FormattedMessage id={`app.${text}`} />
      </StyledSidebarLinkText>
    </>
  );
  return (
    <StyledSidebarListItem
      key={i}
      sectionItem={section}
      spacingAfter={spacingAfter}
    >
      {section && <FormattedMessage id={`app.${text}`} />}
      {to && (
        <NavLink to={to} {...linkProps} {...clientMainNavItemHandle(text)}>
          {linkChildren}
        </NavLink>
      )}
      {!to && href && (
        <a href={href} {...rest} {...clientMainNavItemHandle(text)}>
          {linkChildren}
        </a>
      )}
    </StyledSidebarListItem>
  );
};

export type SidebarProps = { items: ReadonlyArray<NavItem>; user: User };

const ProfileNavLink = (props: { user: User; url: string }) => (
  <NavLink to={props.url} tabIndex={-1}>
    <InitialismIconStatic user={props.user} />
    <StyledName>{generateName(props.user as UserProps)}</StyledName>
  </NavLink>
);

export const ProfileSelectorLinkIconWithName = (props: { user: User }) => (
  <ProfileButton
    data-testid="switch-profile"
    role="button"
    tabIndex={0}
    onClick={() => resetProfileSelectorCallback()}
    onKeyDown={e => {
      e.key === "Enter" && resetProfileSelectorCallback();
    }}
  >
    <InitialismIconStatic user={props.user} />
    <StyledName>{generateName(props.user as UserProps)}</StyledName>
  </ProfileButton>
);

function Sidebar({ items, user }: SidebarProps) {
  const location = useLocation();

  return (
    <SidebarContainer role="navigation" className="Sidebar_mainNav">
      <StyledSidebar>
        <StyledSidebarItem aria-hidden>
          <FormattedMessage id={`navbar.front_page`}>
            {message => (
              <StyledSidebarLogo>
                {location.pathname === "/" ? (
                  <Logo alt={message as unknown as string} />
                ) : (
                  <Link to="/" tabIndex={-1}>
                    <Logo alt={message as unknown as string} />
                  </Link>
                )}
              </StyledSidebarLogo>
            )}
          </FormattedMessage>
        </StyledSidebarItem>
        <StyledSidebarList {...clientMainNavHandle()}>
          {items.map(renderItem)}
        </StyledSidebarList>

        <StyledSidebarItem>
          {profileSelectorShowable(user) ? (
            <ProfileSelectorSidebarWrapper>
              <FormattedMessage id="people.change_profile" />
              <StyledSidebarProfile>
                <ProfileSelectorLinkIconWithName user={user} />
              </StyledSidebarProfile>
            </ProfileSelectorSidebarWrapper>
          ) : (
            <StyledSidebarProfile>
              <ProfileNavLink user={user} url="/profile" />
            </StyledSidebarProfile>
          )}
        </StyledSidebarItem>
      </StyledSidebar>
    </SidebarContainer>
  );
}

export default Sidebar;
