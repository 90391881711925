import React from "react";
import { IntlProvider } from "react-intl";
import { flattenMessages } from "common/utils/intl";
import { Router } from "react-router";
import SignedInNavBar, { SignedInNavBarProps } from "./SignedInNavBar";
import SignedOutNavBar, { SignedOutNavBarProps } from "./SignedOutNavBar";
import history from "common/utils/history";

type wrapperPropTypes = {
  signedIn: boolean;
  locale: string;
  messages: Record<string, any>;
};
type Props = wrapperPropTypes & (SignedInNavBarProps | SignedOutNavBarProps);

export default function NavBarWrapper(props: Props & { activePath: string }) {
  return (
    <IntlProvider
      textComponent="span"
      messages={flattenMessages(props.messages)}
      locale={props.locale}
      key={props.locale}
    >
      <Router history={history}>
        {props.signedIn ? (
          <SignedInNavBar {...(props as any)} />
        ) : (
          <SignedOutNavBar {...(props as any)} />
        )}
      </Router>
    </IntlProvider>
  );
}
