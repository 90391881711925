import { Spinner } from "@netmedi/frontend-design-system";
import Page from "common/components/Page";
import {
  ConversationListRegular,
  ConversationListSupport,
} from "common/containers/DataTable";
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Redirect } from "react-router-dom";
import { RootState } from "store";
import { SiteSettings } from "common/utils/holvikaari";
import { isClient } from "common/utils/user";
import { User } from "common/models/user";
import { withErrorBoundary } from "common/utils/errorBoundary";

import { connect, ConnectedProps } from "react-redux";
import { getConversations } from "shared/actions/chat";

import { pageLoadFailed } from "common/utils/api";
import ConversationList from "./ConversationList.lazy";

const mapStateToProps = (state: RootState) => ({
  conversations: state.chat.conversations.all ?? [],
  user: state.user,
});

const mapDispatchToProps = { getConversations };

// Component supposed to only be used on the patient UI side because of redirect
// to the conversation if it exist.
export const ClientConversationList = (
  props: ConnectedProps<typeof connector>,
) => {
  const { conversations, getConversations, user } = props;

  useEffect(() => {
    if (!conversations.length && isClient(user)) {
      getConversations().catch(pageLoadFailed);
    }
  }, []);

  const checkRedirect = (
    user: User,
    conversations: any,
  ): JSX.Element | null => {
    if (isClient(user)) {
      return (
        <>
          <Spinner />
          {conversations?.length > 0 ? (
            <Redirect to={`/conversations/${conversations[0].id}`} />
          ) : null}
        </>
      );
    }
    return null;
  };

  const redirect = checkRedirect(user, conversations);
  return redirect ? (
    redirect
  ) : (
    <Page>
      <ConversationList
        ListComponent={ConversationListRegular}
        title={<FormattedMessage id="conversations.my_conversations" />}
        user={user}
      />

      {SiteSettings.enable_support_conversations && (
        <ConversationList
          ListComponent={ConversationListSupport}
          user={user}
          enableSearch={false}
          title={
            <FormattedMessage
              id="conversations.kaiku_development"
              values={{ product_name: SiteSettings.product_name }}
            />
          }
        >
          <FormattedMessage
            tagName="div"
            id="conversations.conversation_with_admin_info"
            values={{ product_name: SiteSettings.product_name }}
          />
        </ConversationList>
      )}
    </Page>
  );
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default withErrorBoundary(connector(ClientConversationList));
