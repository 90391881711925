import React, { forwardRef } from "react";
import cn from "classnames";

type LeftNavProp = {
  navElements: JSX.Element[];
  className: string;
};

const renderLeftNav: React.ForwardRefRenderFunction<
  HTMLDivElement,
  LeftNavProp
> = (props, ref) => (
  <div className={cn("nav", "navBarLeft", props.className)} ref={ref}>
    {props.navElements?.map((element, i) => (
      <React.Fragment key={i}>{element}</React.Fragment>
    ))}
  </div>
);
export const LeftNav = forwardRef<HTMLDivElement, LeftNavProp>(renderLeftNav);

type rightNavPropTypes = {
  navElements: JSX.Element[];
};

export const RightNav = (props: rightNavPropTypes) => {
  return (
    <div className="nav pull-right">
      {props.navElements?.map((element, i) => (
        <React.Fragment key={i}>{element}</React.Fragment>
      ))}
    </div>
  );
};

type NavBarProp = {
  rootPath: string;
  logoPath: string;
  leftNav?: JSX.Element;
  rightNav?: JSX.Element;
};

export default function NavBar(props: NavBarProp) {
  return (
    <div className="navbar" id="navbar" role="navigation">
      <div className="navbar-inner">
        {(props.leftNav || props.rightNav) && (
          <div
            className="btn btn-navbar"
            data-toggle="collapse"
            data-target=".nav-collapse"
          >
            <span className="icon-bar" />
            <span className="icon-bar" />
            <span className="icon-bar" />
          </div>
        )}

        <a href={props.rootPath} className="brand" id="navbar-logo-link">
          <img src={props.logoPath} alt="Kaiku Health" />
        </a>

        <div className={cn("nav-collapse", "navBarContainer")}>
          {props.leftNav ?? null}
          {props.rightNav ?? null}
        </div>
      </div>
    </div>
  );
}
